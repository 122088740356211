<template>
  <div>
    <div id="customer" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="contact_mail"></b-icon> Kunde
      </h2>
      <customer-find
        :customer="customer"
        v-if="!customerSelected"
        class="panel-block"
        @customer-selection-changed="customerSelectionChanged"
      />
      <customer-field
        :customer="customer"
        v-if="customerSelected"
        :changeLink="true"
        class="panel-block"
        @customer-selection-changed="customerSelectionChanged"
      />
      <div class="panel-block columns" v-if="customerSelected">
        <div class="column">
          <b-field label="Info (optional)">
            <b-input v-model.trim.lazy="info"> </b-input>
          </b-field>
          <b-field label="Ankauf von Privat">
              <b-switch
                  v-model="from_private"
                  true-value="1"
                  false-value="0"
                  size="is-small"
                >
                </b-switch>keine ausgewiesene MwSt. = Differenzbesteuerung
            </b-field>
        </div>

      </div>
    </div>

    <div id="wines" v-if="customerSelected" class="panel">
      <h2 class="panel-heading is-4"><b-icon icon="bottles"></b-icon> Weine</h2>
      <wine-field
        class="panel-block"
        :id="'wine' + w.key"
        v-for="(w, index) in wines"
        :key="index"
        :w="w"
        :wineIndex="index"
        :inputmode="true"
        :priceChangeAllowed="false"
        @wine-delete="wineDelete"
        @wine-copy="wineCopy"
      />
    </div>
    <button @click="addWinefield()" v-if="customerSelected" class="button">
      Wein hinzufügen
    </button>

    <div class="panel-block">
      <router-link
        to="/consignments/index"
        class="button"
        type="button"
        custom
        v-slot="{ navigate }"
      >
        <button @click="navigate" role="link" class="button">
          Zurück zur Übersicht
        </button></router-link
      >
      &nbsp;
      <b-button
        @click="saveConsignment()"
        v-if="customerSelected && winesSelected"
        class="button"
        type="is-info"
        icon-left="cloud_upload"
        native-type="submit"
      >
        Speichern
      </b-button>
    </div>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import CustomerField from "../_helpers/customerfield.vue"
import CustomerFind from "../_helpers/customerfind.vue"
import WineField from "../_helpers/winefield.vue"
export default {
  name: "consignmentForm",
  components: {
    CustomerField,
    CustomerFind,
    WineField
  },

  data() {
    return {
      customer: {},
      customerSelected: false,
      winesSelected: false,
      noOfWineFields: 0,
      wines: [],
      info: "",
      from_private:0,
      isLoading: false,
      selectFee: this.selectConsignmentFee()
    }
  },

  mounted: function() {
    this.$store.commit("setHeadline", {
      subheadline: "Neuen Ankauf anlegen"
    })
  },
  methods: {
    // You have to install and import debounce to use it,
    // it's not mandatory though.

    addWinefield() {
      this.noOfWineFields++

      this.wines.push({
        index: this.noOfWineFields,
        key: "wine" + this.noOfWineFields,
        bottles: 1,
        price_ek: "0",
        price_vk: "0",
        label: "pf",
        capsula: "pf",
        fill_level: "hf",
        packaging: "no",
        article_id: 0,
        wine_id: 0,
        info_de: "",
        info_en: "",
        article: "",
        images: [],
        fine_wine: 1,
      })
    },

    customerSelectionChanged(customer) {
      if (typeof customer == "object") {
        this.customer = customer
        this.customer.fee = customer.consignment_fee
        this.customerSelected = true
        if (this.noOfWineFields == 0) {
          this.addWinefield()
        }
      } else {
        this.customer = {}
        this.customerSelected = false
      }
    },
    async saveConsignment() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/consignments/save", {
          type: "saveNewConsignment",
          customer: t.customer,
          wines: t.wines,
          info: t.info,
          from_private: t.from_private
        })
        .then(function(resp) {
          t.isLoading = false
          //Daten in Eltern setzen
          t.$parent.consignment = resp.data.data
          //Auf Ansicht umleiten
          t.$router.push({ path: "/consignments/view/" + resp.data.data.id })
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    },
    wineDelete(id) {
      //console.log("Läsche index " + index);
      this.wines[id].id = "deleted"
      this.wines[id].article_id = 0
      this.wines[id].wine_id = 0
      this.wines[id].bottles = 0
    },
    wineCopy(id, no = 1) {
      var t = this

      for (var i = 0; i < no; i++) {
        t.noOfWineFields++
        t.wines.push({
          index: t.noOfWineFields,
          key: "wine" + t.noOfWineFields,
          bottles: 1,
          price_ek: t.wines[id].price_ek,
          price_exp: t.wines[id].price_exp,
          label: t.wines[id].label,
          capsula: t.wines[id].capsula,
          fill_level: t.wines[id].fill_level,
          packaging: t.wines[id].packaging,
          article_id: t.wines[id].article_id,
          wine_id: t.wines[id].wine_id,
          info_de: t.wines[id].info_de,
          info_en: t.wines[id].info_en,
          article: t.wines[id].article,
          images: []
        })
      }
    },
    selectConsignmentFee: function() {
      var sel = []
      for (var i = 0; i <= 25; i++) {
        sel.push({ id: i, name: i + " Prozent" })
      }
      return sel
    }
  }
}
</script>
